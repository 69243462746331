import Link from 'next/link';
import { Button, Icon } from '@/components/standalone';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useWindowSize, { BREAKPOINTS } from '@/helpers/useWindowSize';
import useOnScreen from '@/helpers/useOnScreen';
import { useRouter } from 'next/router';
import styles from './Navbar.module.scss';

function Navbar() {
  const { deviceScreenType } = useWindowSize();

  const router = useRouter();
  const shouldAppButtonHaveBorder =
    router?.route === '/' || router.route.includes('/blog');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallNavbar, setIsSmallNavbar] = useState(false);
  const { observe, unobserve } = useOnScreen({ isAutomatic: false });
  const finalLogo = useMemo(() => {
    if (deviceScreenType !== BREAKPOINTS.SM) {
      return 'TrustblockLogoFull';
    }
    return 'TrustblockLogoMark';
  }, [deviceScreenType]);

  useEffect(() => {
    const ref = { current: document?.getElementById('first') };
    let observer;
    if (ref?.current) {
      observer = observe({
        ref,
        threshold: 0.7,
        callback: (isOnScreen) => {
          setIsSmallNavbar(!isOnScreen);
        },
      });
    }

    return () => {
      if (ref?.current) {
        unobserve(ref, observer);
      }
    };
  }, [router?.route]);

  return (
    <nav
      className={classNames(styles.navbar, {
        [styles.navbarSmall]:
          isSmallNavbar ||
          deviceScreenType === BREAKPOINTS.MD ||
          deviceScreenType === BREAKPOINTS.SM,
      })}
    >
      <div className={styles.navbarBackground} />
      <ul
        className={classNames({
          'max-content-width': deviceScreenType !== BREAKPOINTS.SM,
        })}
      >
        <li
          className={classNames(styles.navbarLogo, {
            [styles.navbarLogoOpen]: false,
          })}
        >
          <Link
            onClick={() => setIsMenuOpen(false)}
            href="/"
            alt="homepage-link"
          >
            <Icon name={finalLogo} />
          </Link>
        </li>
        <li className={styles.navbarMobileMenuTrigger}>
          <Button
            type="button"
            mode="outline"
            active={isMenuOpen}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
            onlyIcon
          >
            <Icon name="Menu" />
          </Button>
        </li>
        <li
          className={classNames(styles.navbarMobileMenu, {
            [styles.navbarMobileMenuOpen]: isMenuOpen,
          })}
        >
          <ul>
            <li className={styles.navbarMobileItem}>
              <Link onClick={() => setIsMenuOpen(false)} href="/sec-api">
                Security Data API
              </Link>
            </li>
            <li className={styles.navbarMobileItem}>
              <Link onClick={() => setIsMenuOpen(false)} href="/auditors">
                Auditors
              </Link>
            </li>
            <li className={styles.navbarMobileItem}>
              <Link
                onClick={() => setIsMenuOpen(false)}
                href="https://docs.trustblock.run"
              >
                Documentation
              </Link>
            </li>
            <li className={styles.navbarMobileItem}>
              <Link onClick={() => setIsMenuOpen(false)} href="/blog">
                Blog
              </Link>
            </li>
            <li className={styles.navbarMobileItem}>
              <Link
                onClick={() => setIsMenuOpen(false)}
                href="https://app.trustblock.run"
              >
                Go to app <Icon name="ArrowRight" />
              </Link>
            </li>
          </ul>
        </li>
        <li className={styles.navbarItem}>
          <Link href="/sec-api">Security Data API</Link>
        </li>
        <li className={styles.navbarItem}>
          <Link href="/auditors">Auditors</Link>
        </li>
        <li className={styles.navbarItem}>
          <Link href="https://docs.trustblock.run">Documentation</Link>
        </li>
        <li className={styles.navbarItem}>
          <Link href="/blog">Blog</Link>
        </li>
        <li className={styles.navbarItemButton}>
          <Button
            type="link"
            href="https://app.trustblock.run"
            mode="secondary"
            icon="ArrowRight"
            className={classNames({
              [styles.navbarItemButtonBorder]: shouldAppButtonHaveBorder,
            })}
          >
            Go to app
          </Button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
